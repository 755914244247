import { BotName, Locale, SalesLineLabel, TimeZone } from '@cca/util';

import Environment from '../../Environment.type';

const environment: Environment = {
  isDevelopment: false,
  isQa: true,
  isProduction: false,

  theme: 'mm',
  middlewareUrl: `${window.location.protocol}//${window.location.host}/api`,
  locale: Locale.deAT,
  parentWindowUrl: 'https://mm-at-de--dev.custhelp.com/',
  logging: true,

  geolocationRequestTimeout: 20000,

  botName: BotName.Emmi,
  surveyType: 'internal',
  allOutletsClosed: false,
  showOutletInformation: false,
  showOutletStatus: true,
  salesLineLabel: SalesLineLabel.MediaMarkt,
  covidGeneralInformationUrl:
    'https://red.mediamarkt.at/covid-19-sicherheitsmassnahmen.html',

  triggerContactCardAfterNegativeFlowFeedback: false,
  showContactCardIndicator: true,
  showContactCardPhoneNumber: true,
  callCenterTimezone: TimeZone.EurVienna,
  showOpeningTimeExceptions: false,

  enableClickTracking: false,

  productCardStrikePricePrefix: 'UVP',
  productCardStrikePriceCrossedOut: true,

  hideOutletPhoneNumber: false,
};

export default environment;
