import { BotName, Locale, SalesLineLabel, TimeZone } from '@cca/util';

import Environment from '../../Environment.type';

const environment: Environment = {
  isDevelopment: true,
  isQa: false,
  isProduction: false,

  theme: 'mm',
  middlewareUrl: `${window.location.protocol}//${window.location.host}/fr/api`,
  locale: Locale.frBE,
  parentWindowUrl: 'https://mm-be-fr--dev.custhelp.com',
  logging: true,

  geolocationRequestTimeout: 20000,

  botName: BotName.Emmi,
  surveyType: 'internal',
  allOutletsClosed: false,
  showOutletInformation: false,
  showOutletStatus: true,
  salesLineLabel: SalesLineLabel.MediaMarkt,
  covidGeneralInformationUrl:
    'https://www.mediamarkt.de/de/shop/coronainfos.html#marktinfos', // TO DO: change

  triggerContactCardAfterNegativeFlowFeedback: false,
  showContactCardIndicator: true,
  showContactCardPhoneNumber: true,
  callCenterTimezone: TimeZone.EurBerlin,
  showOpeningTimeExceptions: true,

  enableClickTracking: false,

  productCardStrikePricePrefix: '',
  productCardStrikePriceCrossedOut: false,

  hideOutletPhoneNumber: true,
};

export default environment;
