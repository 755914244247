import { BotName, Locale, SalesLineLabel, TimeZone } from '@cca/util';

import Environment from '../../Environment.type';

const environment: Environment = {
  isDevelopment: true,
  isQa: false,
  isProduction: false,

  theme: 'se',
  middlewareUrl: `${window.location.protocol}//${window.location.host}/api`,
  locale: Locale.deDE,
  parentWindowUrl: 'https://se-de-de--dev.custhelp.com',
  logging: true,

  geolocationRequestTimeout: 20000,

  surveyType: 'internal',
  botName: BotName.Sammy,
  allOutletsClosed: false,
  showOutletInformation: false,
  showOutletStatus: true,
  salesLineLabel: SalesLineLabel.Saturn,
  covidGeneralInformationUrl:
    'https://www.saturn.de/de/shop/coronainfos.html#marktinfos',

  triggerContactCardAfterNegativeFlowFeedback: false,
  showContactCardIndicator: true,
  showContactCardPhoneNumber: true,
  callCenterTimezone: TimeZone.EurBerlin,
  showOpeningTimeExceptions: true,

  enableClickTracking: false,

  productCardStrikePricePrefix: 'UVP',
  productCardStrikePriceCrossedOut: false,

  hideOutletPhoneNumber: false,
};

export default environment;
