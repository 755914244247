import { ComponentType, ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../hocs';
import { ListItem } from '../../list';
import { OpeningTime } from '../../opening-times';
import AddressEntry, {
  AddressEntryProps,
} from '../outlet-detail/entries/AddressEntry';
import CityEntry, { CityEntryProps } from '../outlet-detail/entries/CityEntry';
import OpeningTimeEntry, {
  OpeningTimeEntryProps,
} from '../outlet-detail/entries/OpeningTimeEntry';
import PhoneEntry, {
  PhoneEntryProps,
} from '../outlet-detail/entries/PhoneEntry';

export type OutletListItemProps = {
  address: string;
  phoneNumber?: string;
  openingTimes: OpeningTime[];
  openingTimeExceptionMessage?: string;
  city: string;
  distance: number;
  isOpened: boolean;
  openedLabel: string;
  closedLabel: string;
  onClick?: () => void;
  showOutletStatus?: boolean;
  CityEntryComponent?: ComponentType<CityEntryProps>;
  AddressEntryComponent?: ComponentType<AddressEntryProps>;
  PhoneEntryComponent?: ComponentType<PhoneEntryProps>;
  OpeningTimeEntryComponent?: ComponentType<OpeningTimeEntryProps>;
};
const OutletListItem = (
  {
    address,
    phoneNumber,
    openingTimes,
    openingTimeExceptionMessage,
    city,
    distance,
    isOpened,
    openedLabel,
    closedLabel,
    onClick = () => {},
    showOutletStatus = true,
    CityEntryComponent = CityEntry,
    AddressEntryComponent = AddressEntry,
    PhoneEntryComponent = PhoneEntry,
    OpeningTimeEntryComponent = OpeningTimeEntry,
  }: OutletListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  return (
    <ListItem ref={ref} withLink onClick={onClick}>
      <CityEntryComponent
        distance={distance}
        city={city}
        showOutletStatus={showOutletStatus}
        isOpened={isOpened}
        openedLabel={openedLabel}
        closedLabel={closedLabel}
      />
      <AddressEntryComponent address={address} />
      {phoneNumber && <PhoneEntryComponent phoneNumber={phoneNumber} />}
      <OpeningTimeEntryComponent
        openingTimes={openingTimes}
        openingTimeExceptionMessage={openingTimeExceptionMessage}
      />
    </ListItem>
  );
};

export default forwardRefWithDisplayName(OutletListItem, 'OutletListItem');
