import pick from 'lodash/pick';

import { Channel } from '@cca/types';

import { Profile, profileChannelMapping } from './common';

export function profileToChannel(profile: Profile): Channel {
  return pick(profileChannelMapping[profile], [
    'subsidiary',
    'salesLine',
    'language',
  ]);
}
