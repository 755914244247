import { graphql } from '@cca/chatbot-graphql-types';

const faqWithScoreFragmentGql = graphql(/* GraphQL*/ `
  fragment FaqWithScoreFields on FaqWithScore {
    score
    faq {
      id
      created
      updated
      summary
      keywords
      question
      answer
      markdownAnswer
      strippedAnswer
      language
      categories
      shortAnswer
      accessCount
      rank1Count
      rank2Count
      rank3Count
      rank4Count
      rank5Count
      url
    }
  }
`);

export default faqWithScoreFragmentGql;
