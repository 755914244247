import {
  AccordionListItem,
  AddressEntry,
  BackButton,
  BotAvatar,
  BotMessageEntry,
  Button,
  Card,
  CardHeader,
  CityEntry,
  ContactCard,
  ExpandButton,
  FlowFeedback,
  Footer,
  Header,
  Heading,
  List,
  ListItem,
  Main,
  Message,
  MinimizeButton,
  Modal,
  NavigationButton,
  OpeningTimeEntry,
  OutletDetail,
  OutletListItem,
  OutletLocationMap,
  PhoneEntry,
  ProductAvailability,
  ProductEfficiency,
  ProductExpand,
  ProductImage,
  ProductPrice,
  ProductRating,
  ProductTile,
  QuickReply,
  Rating,
  SearchInput,
  Sender,
  ServiceSiteListItem,
  SubHeading,
  SuggestionList,
  SuggestionListItem,
  Survey,
  Text,
  TextAreaInput,
  TextInput,
  ThumbsFeedback,
  UserMessageEntry,
} from '@cca/ui';

import withUiComponentClickTrackingFactory from '../withUiComponentClickTrackingFactory';

export const [
  TCard,
  TCardHeader,
  THeading,
  TSubHeading,
  TList,
  TAccordionListItem,
  TListItem,
  TBotAvatar,
  TContactCard,
  TMessage,
  TSearchInput,
  TFlowFeedback,
  TQuickReply,
  THeader,
  TFooter,
  TMain,
  TNavigationButton,
  TButton,
  TTextInput,
  TProductTile,
  TProductAvailability,
  TProductEfficiency,
  TProductImage,
  TProductRating,
  TProductPrice,
  TProductExpand,
  TText,
  TModal,
  TSurvey,
  TBackButton,
  TThumbsFeedback,
  TRating,
  TTextAreaInput,
  TMinimizeButton,
  TUserMessageEntry,
  TBotMessageEntry,
  TSuggestionList,
  TSuggestionListItem,
  TSender,
  TOutletDetail,
  TOutletListItem,
  TOutletLocationMap,
  TCityEntry,
  TAddressEntry,
  TPhoneEntry,
  TOpeningTimeEntry,
  TServiceSiteListItem,
  TExpandButton,
] = withUiComponentClickTrackingFactory([
  Card,
  CardHeader,
  Heading,
  SubHeading,
  List,
  AccordionListItem,
  ListItem,
  BotAvatar,
  ContactCard,
  Message,
  SearchInput,
  FlowFeedback,
  QuickReply,
  Header,
  Footer,
  Main,
  NavigationButton,
  Button,
  TextInput,
  ProductTile,
  ProductAvailability,
  ProductEfficiency,
  ProductImage,
  ProductRating,
  ProductPrice,
  ProductExpand,
  Text,
  Modal,
  Survey,
  BackButton,
  ThumbsFeedback,
  Rating,
  TextAreaInput,
  MinimizeButton,
  UserMessageEntry,
  BotMessageEntry,
  SuggestionList,
  SuggestionListItem,
  Sender,
  OutletDetail,
  OutletListItem,
  OutletLocationMap,
  CityEntry,
  AddressEntry,
  PhoneEntry,
  OpeningTimeEntry,
  ServiceSiteListItem,
  ExpandButton,
]);
