/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: CCA.GraphQLDateTime;
  /** A simple EnergyEfficiency parser */
  EnergyEfficiencyClass: CCA.GraphQLEnergyEfficiencyClass;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: CCA.GraphQLJSON;
};

export enum BotAvatarKind {
  error = 'error',
  feedback = 'feedback',
  service = 'service',
}

export type BotAvatarMessage = {
  __typename?: 'BotAvatarMessage';
  id: Scalars['String'];
  kind: BotAvatarKind;
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type BotResponse = {
  __typename?: 'BotResponse';
  id: Scalars['String'];
  messages: Array<Message>;
  sessionId: Scalars['String'];
};

export type CardMessage = {
  __typename?: 'CardMessage';
  heading?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  list?: Maybe<Array<ListEntry>>;
  preventDisable: Scalars['Boolean'];
  scope: Scalars['String'];
  subheading?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  language: Language;
  salesLine: SalesLine;
  subsidiary: Subsidiary;
};

export type ChannelInput = {
  language: Language;
  salesLine: SalesLine;
  subsidiary: Subsidiary;
};

export type ClickActionInput = {
  parameters?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type ClickBotStateInput = {
  responseId: Scalars['String'];
};

export type ClickContextInput = {
  messageId: Scalars['String'];
  responseId: Scalars['String'];
};

export type ClickPositionInput = {
  viewPortHeight: Scalars['Int'];
  viewPortWidth: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ClickTrackingInput = {
  action?: InputMaybe<ClickActionInput>;
  botState: ClickBotStateInput;
  context?: InputMaybe<ClickContextInput>;
  position: ClickPositionInput;
  rendered: Scalars['DateTime'];
  sessionId: Scalars['String'];
  uiComponent: Scalars['String'];
};

export type ClickTrackingResult = {
  __typename?: 'ClickTrackingResult';
  created: Scalars['DateTime'];
  id: Scalars['String'];
  language: Language;
  salesLine: SalesLine;
  sessionId: Scalars['String'];
  subsidiary: Subsidiary;
};

export type ContactCardMessage = {
  __typename?: 'ContactCardMessage';
  availableLabel: Scalars['String'];
  callInvitationLabel: Scalars['String'];
  contactFormLabel: Scalars['String'];
  contactFormLink: Scalars['String'];
  heading: Scalars['String'];
  holidays?: Maybe<ContactCardOpenings>;
  id: Scalars['String'];
  notAvailableLabel: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneNumberInfo?: Maybe<Scalars['String']>;
  saturday?: Maybe<ContactCardOpenings>;
  scope: Scalars['String'];
  timeLabel: Scalars['String'];
  type: Scalars['String'];
  weekdays: ContactCardOpenings;
};

export type ContactCardOpenings = {
  __typename?: 'ContactCardOpenings';
  closingTime: Scalars['String'];
  label: Scalars['String'];
  openingTime: Scalars['String'];
};

export enum DialogflowActionTypes {
  event = 'event',
  intent = 'intent',
  text = 'text',
}

export enum EnergyEfficiencyClassEu2017_1369 {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export type EnvironmentInterpolation = Interpolation & {
  __typename?: 'EnvironmentInterpolation';
  name: Scalars['String'];
  type: InterpolationType;
};

export type Faq = {
  __typename?: 'Faq';
  accessCount: Scalars['Int'];
  answer: Scalars['String'];
  categories: Array<Scalars['String']>;
  /** unix timestamp */
  created: Scalars['Float'];
  id: Scalars['Int'];
  keywords: Array<Scalars['String']>;
  language: Scalars['String'];
  markdownAnswer: Scalars['String'];
  question: Scalars['String'];
  rank1Count: Scalars['Int'];
  rank2Count: Scalars['Int'];
  rank3Count: Scalars['Int'];
  rank4Count: Scalars['Int'];
  rank5Count: Scalars['Int'];
  shortAnswer?: Maybe<Scalars['String']>;
  strippedAnswer: Scalars['String'];
  summary: Scalars['String'];
  /** unix timestamp */
  updated: Scalars['Float'];
  url: Scalars['String'];
};

export type FaqCardMessage = {
  __typename?: 'FaqCardMessage';
  faqWithScore: Array<FaqWithScore>;
  id: Scalars['String'];
  scope: Scalars['String'];
  serviceSite?: Maybe<ServiceSite>;
  type: Scalars['String'];
};

export type FaqSearchResult = {
  __typename?: 'FaqSearchResult';
  faqsWithScore: Array<FaqWithScore>;
  pagination: Pagination;
};

export type FaqWithScore = {
  __typename?: 'FaqWithScore';
  faq: Faq;
  /** search score assigned from mongo */
  score: Scalars['Float'];
};

export type FeedbackFlowContext = {
  __typename?: 'FeedbackFlowContext';
  label: Scalars['String'];
  messageId: Scalars['String'];
  /** The last response id of the conversation history at the moment the feedback was triggered. */
  responseId: Scalars['String'];
};

export type FeedbackFlowContextInput = {
  label: Scalars['String'];
  messageId: Scalars['String'];
  /** The last response id of the conversation history at the moment the feedback was triggered. */
  responseId: Scalars['String'];
};

export type FeedbackIntent = {
  __typename?: 'FeedbackIntent';
  displayName: Scalars['String'];
  id: Scalars['String'];
  messageId: Scalars['String'];
  responseId: Scalars['String'];
};

export type FeedbackIntentInput = {
  displayName: Scalars['String'];
  id: Scalars['String'];
  messageId: Scalars['String'];
  responseId: Scalars['String'];
};

export type FeedbackResult =
  | FeedbackResultWithFlowContext
  | FeedbackResultWithIntent;

export type FeedbackResultWithFlowContext = {
  __typename?: 'FeedbackResultWithFlowContext';
  created: Scalars['DateTime'];
  displayed: Scalars['DateTime'];
  flowContext: FeedbackFlowContext;
  id: Scalars['String'];
  language: Language;
  salesLine: SalesLine;
  sessionId: Scalars['String'];
  subsidiary: Subsidiary;
  type: FeedbackType;
  value: FeedbackValue;
};

export type FeedbackResultWithIntent = {
  __typename?: 'FeedbackResultWithIntent';
  created: Scalars['DateTime'];
  displayed: Scalars['DateTime'];
  id: Scalars['String'];
  intent: FeedbackIntent;
  language: Language;
  salesLine: SalesLine;
  sessionId: Scalars['String'];
  subsidiary: Subsidiary;
  type: FeedbackType;
  value: FeedbackValue;
};

export type FeedbackSurveyInput = {
  category: SurveyCategoryValue;
  context: SurveyContextInput;
  feedbackText: Scalars['String'];
  rendered: Scalars['DateTime'];
  sessionId: Scalars['String'];
};

export type FeedbackSurveyResult = {
  __typename?: 'FeedbackSurveyResult';
  category: SurveyCategoryValue;
  context: SurveyContext;
  created: Scalars['DateTime'];
  feedbackText: Scalars['String'];
  id: Scalars['String'];
  language: Language;
  rendered: Scalars['DateTime'];
  salesLine: SalesLine;
  sessionId: Scalars['String'];
  subsidiary: Subsidiary;
};

export enum FeedbackType {
  FlowContext = 'FlowContext',
  Intent = 'Intent',
}

export enum FeedbackValue {
  Dislike = 'Dislike',
  Helped = 'Helped',
  Like = 'Like',
}

export type FlowFeedback = {
  displayed: Scalars['DateTime'];
  flowContext: FeedbackFlowContextInput;
  sessionId: Scalars['String'];
  type: FeedbackType;
  value: FeedbackValue;
};

export type FlowFeedbackMessage = {
  __typename?: 'FlowFeedbackMessage';
  flowContext: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type Geolocation = {
  __typename?: 'Geolocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeolocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type IntentContext = {
  __typename?: 'IntentContext';
  displayName: Scalars['String'];
  id: Scalars['String'];
};

export type IntentFeedback = {
  displayed: Scalars['DateTime'];
  intent: FeedbackIntentInput;
  sessionId: Scalars['String'];
  type: FeedbackType;
  value: FeedbackValue;
};

export type InterpolatedTextMessage = {
  __typename?: 'InterpolatedTextMessage';
  id: Scalars['String'];
  interpolations: Array<Interpolation>;
  scope: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type Interpolation = {
  name: Scalars['String'];
  type: InterpolationType;
};

export enum InterpolationType {
  environment = 'environment',
  translation = 'translation',
}

export enum Language {
  de = 'de',
  es = 'es',
  fr = 'fr',
  nl = 'nl',
}

export type ListEntry = {
  __typename?: 'ListEntry';
  action?: Maybe<ReplyAction>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LocationSearchInputMessage = {
  __typename?: 'LocationSearchInputMessage';
  action: ReplyAction;
  enableLocationLabel: Scalars['String'];
  id: Scalars['String'];
  locationDefaultFailureAction: ReplyAction;
  locationPermissionFailureAction: ReplyAction;
  locationTimeoutFailureAction: ReplyAction;
  placeholder: Scalars['String'];
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type LocationSuggestion = {
  __typename?: 'LocationSuggestion';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type LocationSuggestions = {
  __typename?: 'LocationSuggestions';
  suggestions: Array<LocationSuggestion>;
};

export type Message =
  | BotAvatarMessage
  | CardMessage
  | ContactCardMessage
  | FaqCardMessage
  | FlowFeedbackMessage
  | InterpolatedTextMessage
  | LocationSearchInputMessage
  | OutletsCardMessage
  | ProductCardMessage
  | ProductSearchInputMessage
  | QuickReplyMessage
  | SurveyButtonMessage
  | TextMessage;

export type Mutation = {
  __typename?: 'Mutation';
  clickTracking: ClickTrackingResult;
  executeAction: BotResponse;
  sendMessage: BotResponse;
  startConversation: BotResponse;
  submitFlowFeedback: FeedbackResult;
  submitIntentFeedback: FeedbackResult;
  submitUserAgent: UserAgentResult;
  survey: FeedbackSurveyResult;
};

export type MutationClickTrackingArgs = {
  clickTracking: ClickTrackingInput;
};

export type MutationExecuteActionArgs = {
  action: ReplyActionInput;
  queryParams: QueryParamsInput;
  sessionId: Scalars['String'];
};

export type MutationSendMessageArgs = {
  message: Scalars['String'];
  sessionId: Scalars['String'];
};

export type MutationSubmitFlowFeedbackArgs = {
  feedback: FlowFeedback;
};

export type MutationSubmitIntentFeedbackArgs = {
  feedback: IntentFeedback;
};

export type MutationSubmitUserAgentArgs = {
  userAgent: UserAgentInput;
};

export type MutationSurveyArgs = {
  survey: FeedbackSurveyInput;
};

export enum OnlineAvailability {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
  Preorder = 'Preorder',
  SoonAvailable = 'SoonAvailable',
}

export type OpeningTimeEntry = {
  __typename?: 'OpeningTimeEntry';
  closingTime: Scalars['String'];
  openingTime: Scalars['String'];
  weekDay: Scalars['Int'];
};

export type OpeningTimeExceptionEntry = {
  __typename?: 'OpeningTimeExceptionEntry';
  date: Scalars['String'];
  description: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  billingAddress: OrderAddress;
  fulfillments: Array<OrderFulfillment>;
  hasReturns: Scalars['Boolean'];
  id: Scalars['String'];
  isCancellable: Scalars['Boolean'];
  items: Array<OrderItem>;
  kind?: Maybe<OrderKind>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  street: Scalars['String'];
  type: OrderAddressType;
};

export enum OrderAddressType {
  PackStation = 'PackStation',
  PostOffice = 'PostOffice',
  PudoPoint = 'PudoPoint',
  Standard = 'Standard',
}

export type OrderArticle = {
  __typename?: 'OrderArticle';
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  retailPrice: OrderPrice;
};

export type OrderDeliveryPromise = {
  __typename?: 'OrderDeliveryPromise';
  earliest: Scalars['DateTime'];
  latest: Scalars['DateTime'];
};

export type OrderFulfillment = {
  articles: Array<OrderArticle>;
  origin: OrderOrigin;
  state: OrderFulfillmentState;
};

export type OrderFulfillmentCommon = OrderFulfillment & {
  __typename?: 'OrderFulfillmentCommon';
  articles: Array<OrderArticle>;
  origin: OrderOrigin;
  state: OrderFulfillmentState;
};

export type OrderFulfillmentDelivery = OrderFulfillment & {
  __typename?: 'OrderFulfillmentDelivery';
  articles: Array<OrderArticle>;
  deliveryPromise?: Maybe<OrderDeliveryPromise>;
  id: Scalars['String'];
  origin: OrderOrigin;
  shippingAddress: OrderAddress;
  state: OrderFulfillmentState;
  type: OrderFulfillmentType;
};

export type OrderFulfillmentGlobalHolds = OrderFulfillment & {
  __typename?: 'OrderFulfillmentGlobalHolds';
  articles: Array<OrderArticle>;
  holds: Array<OrderHold>;
  origin: OrderOrigin;
  state: OrderFulfillmentState;
};

export type OrderFulfillmentItem = OrderFulfillment & {
  __typename?: 'OrderFulfillmentItem';
  articles: Array<OrderArticle>;
  itemId: Scalars['String'];
  origin: OrderOrigin;
  quantity: Scalars['Int'];
  state: OrderFulfillmentState;
};

export type OrderFulfillmentItemHolds = OrderFulfillment & {
  __typename?: 'OrderFulfillmentItemHolds';
  articles: Array<OrderArticle>;
  holds: Array<OrderHold>;
  itemId: Scalars['String'];
  origin: OrderOrigin;
  state: OrderFulfillmentState;
};

export type OrderFulfillmentPickup = OrderFulfillment & {
  __typename?: 'OrderFulfillmentPickup';
  articles: Array<OrderArticle>;
  id: Scalars['String'];
  origin: OrderOrigin;
  pickupLocation: OrderPickupLocation;
  state: OrderFulfillmentState;
  type: OrderFulfillmentType;
};

export enum OrderFulfillmentState {
  CancellationRequested = 'CancellationRequested',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Created = 'Created',
  DeliveryPlanned = 'DeliveryPlanned',
  DeliveryShipped = 'DeliveryShipped',
  GlobalHoldsApplied = 'GlobalHoldsApplied',
  ItemHoldsApplied = 'ItemHoldsApplied',
  ItemPlanned = 'ItemPlanned',
  ItemReturned = 'ItemReturned',
  None = 'None',
  PickupCompleted = 'PickupCompleted',
  PickupPlanned = 'PickupPlanned',
}

export enum OrderFulfillmentType {
  Delivery = 'Delivery',
  Pickup = 'Pickup',
  Transfer = 'Transfer',
}

export type OrderHold = {
  __typename?: 'OrderHold';
  reason?: Maybe<Scalars['String']>;
  state: OrderHoldState;
  type: Scalars['String'];
};

export enum OrderHoldState {
  Applied = 'Applied',
  Resolved = 'Resolved',
}

export type OrderItem = {
  __typename?: 'OrderItem';
  name: Scalars['String'];
  productId: Scalars['String'];
};

export enum OrderKind {
  Exchange = 'Exchange',
  Order = 'Order',
}

export enum OrderOrigin {
  MMS = 'MMS',
  Marketplace = 'Marketplace',
}

export type OrderPickupLocation = {
  __typename?: 'OrderPickupLocation';
  outlet: OrderPickupLocationOutlet;
  type: OrderPickupLocationType;
};

export type OrderPickupLocationOutlet = {
  __typename?: 'OrderPickupLocationOutlet';
  address: OrderAddress;
  name: Scalars['String'];
};

export enum OrderPickupLocationType {
  PickupAtDriveThrough = 'PickupAtDriveThrough',
  PickupInStore = 'PickupInStore',
}

export type OrderPrice = {
  __typename?: 'OrderPrice';
  amount: Scalars['Int'];
  currency: Scalars['String'];
};

export type OrderQuery = {
  __typename?: 'OrderQuery';
  id: Scalars['String'];
  order: Order;
};

export type Outlet = {
  __typename?: 'Outlet';
  address: OutletAddress;
  channel: Channel;
  displayName: Scalars['String'];
  geolocation: Geolocation;
  id: Scalars['Int'];
  isOpened: Scalars['Boolean'];
  openingTimeExceptions: Array<OpeningTimeExceptionEntry>;
  openingTimes: Array<OpeningTimeEntry>;
  shortDisplayName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OutletAddress = {
  __typename?: 'OutletAddress';
  city: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  streetNumber?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type OutletSearchResult = {
  __typename?: 'OutletSearchResult';
  /** Nearest outlets to `zipOrCity` query */
  outlets: Array<Outlet>;
  outletsWithDistance: Array<OutletWithDistance>;
  pagination: Pagination;
};

export type OutletWithDistance = {
  __typename?: 'OutletWithDistance';
  /** distance in meters */
  distance: Scalars['Float'];
  outlet: Outlet;
};

export type OutletsCardMessage = {
  __typename?: 'OutletsCardMessage';
  id: Scalars['String'];
  outletsWithDistance: Array<OutletWithDistance>;
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  /** how many elements one page should include */
  pageSize: Scalars['Int'];
  /** how many pages has been skipped */
  skipPages: Scalars['Int'];
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginationOptionsInput = {
  /** how many elements one page should include */
  pageSize?: Scalars['Int'];
  /** how many pages should be skipped */
  skipPages?: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  basePrice?: Maybe<ProductBasePrice>;
  energyEfficiencyClass?: Maybe<Scalars['EnergyEfficiencyClass']>;
  energyEfficiencyClassEu2017_1369?: Maybe<EnergyEfficiencyClassEu2017_1369>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  mainFeatures: Array<ProductMainFeature>;
  name: Scalars['String'];
  onlineAvailability: OnlineAvailability;
  price: ProductPrice;
  rating?: Maybe<ProductRating>;
  url?: Maybe<Scalars['String']>;
};

export type ProductAvailability = {
  __typename?: 'ProductAvailability';
  outletsWithDistance: Array<OutletWithDistance>;
  pagination: Pagination;
  productId: Scalars['String'];
};

export type ProductBasePrice = {
  __typename?: 'ProductBasePrice';
  baseInfo: Scalars['String'];
  calculationFactor: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type ProductCardMessage = {
  __typename?: 'ProductCardMessage';
  availabilityAction?: Maybe<ReplyAction>;
  id: Scalars['String'];
  product: Product;
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type ProductMainFeature = {
  __typename?: 'ProductMainFeature';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  currency: Scalars['String'];
  recommendedRetailPrice?: Maybe<Scalars['Float']>;
  salesPrice: Scalars['Float'];
  strikePrice?: Maybe<Scalars['Float']>;
  strikePriceType?: Maybe<StrikePriceType>;
};

export type ProductRating = {
  __typename?: 'ProductRating';
  averageRating?: Maybe<Scalars['Float']>;
  ratingsCount: Scalars['Int'];
};

export type ProductSearchInputMessage = {
  __typename?: 'ProductSearchInputMessage';
  action: ReplyAction;
  id: Scalars['String'];
  placeholder: Scalars['String'];
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult';
  pagination: Pagination;
  productIds: Array<Scalars['String']>;
  products: Array<Product>;
};

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  value: Scalars['String'];
};

export type ProductSuggestions = {
  __typename?: 'ProductSuggestions';
  maxCount: Scalars['Int'];
  query: Scalars['String'];
  suggestions: Array<ProductSuggestion>;
};

export type Query = {
  __typename?: 'Query';
  getById: Product;
  nearestOutlets: OutletSearchResult;
  orderQuery: OrderQuery;
  outlet: Outlet;
  productAvailability: ProductAvailability;
  productSuggestions: ProductSuggestions;
  searchFaq: FaqSearchResult;
  searchLocation: LocationSuggestions;
  searchProducts: ProductSearchResult;
  searchServiceSites: ServiceSitesSearchResult;
  translations: Translations;
};

export type QueryGetByIdArgs = {
  id: Scalars['String'];
};

export type QueryNearestOutletsArgs = {
  geolocation?: InputMaybe<GeolocationInput>;
  ignoreSalesLine?: InputMaybe<Scalars['Boolean']>;
  paginationOptions: PaginationOptionsInput;
  zipOrCity?: InputMaybe<Scalars['String']>;
};

export type QueryOrderQueryArgs = {
  id: Scalars['String'];
};

export type QueryOutletArgs = {
  id: Scalars['Int'];
};

export type QueryProductAvailabilityArgs = {
  geolocation: GeolocationInput;
  paginationOptions: PaginationOptionsInput;
  productId: Scalars['String'];
};

export type QueryProductSuggestionsArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QuerySearchFaqArgs = {
  paginationOptions: PaginationOptionsInput;
  searchTerm: Scalars['String'];
};

export type QuerySearchLocationArgs = {
  searchTerm: Scalars['String'];
  sessionId: Scalars['String'];
};

export type QuerySearchProductsArgs = {
  paginationOptions: PaginationOptionsInput;
  query: Scalars['String'];
};

export type QuerySearchServiceSitesArgs = {
  query: Scalars['String'];
};

export type QueryTranslationsArgs = {
  lang: Scalars['String'];
};

export type QueryParamsInput = {
  parameters?: InputMaybe<Scalars['JSON']>;
};

export type QuickReplyMessage = {
  __typename?: 'QuickReplyMessage';
  id: Scalars['String'];
  keepPreviousMessagesActive: Scalars['Boolean'];
  options: Array<QuickReplyOption>;
  scope: Scalars['String'];
  type: Scalars['String'];
};

export type QuickReplyOption = {
  __typename?: 'QuickReplyOption';
  action: ReplyAction;
  label: Scalars['String'];
};

export type ReplyAction = {
  __typename?: 'ReplyAction';
  type: DialogflowActionTypes;
  value: Scalars['String'];
};

export type ReplyActionInput = {
  type: DialogflowActionTypes;
  value: Scalars['String'];
};

export enum SalesLine {
  media = 'media',
  saturn = 'saturn',
}

export type ServiceSite = {
  __typename?: 'ServiceSite';
  image: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ServiceSiteResult = {
  __typename?: 'ServiceSiteResult';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ServiceSitesSearchResult = {
  __typename?: 'ServiceSitesSearchResult';
  serviceSites: Array<ServiceSiteResult>;
};

export enum StrikePriceType {
  CrossedOutPrice = 'CrossedOutPrice',
  FinalLastPrice = 'FinalLastPrice',
  LowestOnlinePrice = 'LowestOnlinePrice',
  RecommendedRetailPrice = 'RecommendedRetailPrice',
  StableOnlinePrice = 'StableOnlinePrice',
}

export enum Subsidiary {
  AT = 'AT',
  BE = 'BE',
  DE = 'DE',
  ES = 'ES',
  NL = 'NL',
}

export type SurveyButtonMessage = {
  __typename?: 'SurveyButtonMessage';
  id: Scalars['String'];
  label: Scalars['String'];
  scope: Scalars['String'];
  type: Scalars['String'];
};

export enum SurveyCategoryValue {
  HELPFUL = 'HELPFUL',
  INCORRECT = 'INCORRECT',
  NOT_HELPFUL = 'NOT_HELPFUL',
  OFFENSIVE = 'OFFENSIVE',
}

export type SurveyContext = {
  __typename?: 'SurveyContext';
  messageId: Scalars['String'];
  responseId: Scalars['String'];
};

export type SurveyContextInput = {
  messageId: Scalars['String'];
  responseId: Scalars['String'];
};

export type TextMessage = {
  __typename?: 'TextMessage';
  id: Scalars['String'];
  intent?: Maybe<IntentContext>;
  scope: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type TranslationInterpolation = Interpolation & {
  __typename?: 'TranslationInterpolation';
  name: Scalars['String'];
  parameters: Array<TranslationParameter>;
  type: InterpolationType;
};

export type TranslationParameter = {
  __typename?: 'TranslationParameter';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Translations = {
  __typename?: 'Translations';
  translations?: Maybe<Scalars['JSON']>;
};

export type UserAgentInput = {
  browserName: Scalars['String'];
  deviceType: Scalars['String'];
  osName: Scalars['String'];
  sessionId: Scalars['String'];
};

export type UserAgentResult = {
  __typename?: 'UserAgentResult';
  browserName: Scalars['String'];
  created: Scalars['DateTime'];
  deviceType: Scalars['String'];
  id: Scalars['String'];
  language: Language;
  osName: Scalars['String'];
  salesLine: SalesLine;
  sessionId: Scalars['String'];
  subsidiary: Subsidiary;
};

export type AddClickTrackingMutationVariables = Exact<{
  clickTracking: ClickTrackingInput;
}>;

export type AddClickTrackingMutation = {
  __typename?: 'Mutation';
  clickTracking: {
    __typename?: 'ClickTrackingResult';
    id: string;
    created: CCA.GraphQLDateTime;
  };
};

export type AddIntentFeedbackMutationVariables = Exact<{
  feedback: IntentFeedback;
}>;

export type AddIntentFeedbackMutation = {
  __typename?: 'Mutation';
  submitIntentFeedback:
    | { __typename?: 'FeedbackResultWithFlowContext' }
    | {
        __typename?: 'FeedbackResultWithIntent';
        created: CCA.GraphQLDateTime;
        sessionId: string;
        value: FeedbackValue;
      };
};

export type AddFlowFeedbackMutationVariables = Exact<{
  feedback: FlowFeedback;
}>;

export type AddFlowFeedbackMutation = {
  __typename?: 'Mutation';
  submitFlowFeedback:
    | {
        __typename?: 'FeedbackResultWithFlowContext';
        created: CCA.GraphQLDateTime;
        sessionId: string;
        value: FeedbackValue;
      }
    | { __typename?: 'FeedbackResultWithIntent' };
};

export type SearchLocationQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  sessionId: Scalars['String'];
}>;

export type SearchLocationQuery = {
  __typename?: 'Query';
  searchLocation: {
    __typename?: 'LocationSuggestions';
    suggestions: Array<{
      __typename?: 'LocationSuggestion';
      id: string;
      label: string;
    }>;
  };
};

export type ApiResponseFieldsFragment = {
  __typename?: 'BotResponse';
  id: string;
  sessionId: string;
  messages: Array<
    | ({ __typename?: 'BotAvatarMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_BotAvatarMessage_Fragment: MessagesFields_BotAvatarMessage_Fragment;
        };
      })
    | ({ __typename?: 'CardMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_CardMessage_Fragment: MessagesFields_CardMessage_Fragment;
        };
      })
    | ({ __typename?: 'ContactCardMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_ContactCardMessage_Fragment: MessagesFields_ContactCardMessage_Fragment;
        };
      })
    | ({ __typename?: 'FaqCardMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_FaqCardMessage_Fragment: MessagesFields_FaqCardMessage_Fragment;
        };
      })
    | ({ __typename?: 'FlowFeedbackMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_FlowFeedbackMessage_Fragment: MessagesFields_FlowFeedbackMessage_Fragment;
        };
      })
    | ({ __typename?: 'InterpolatedTextMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_InterpolatedTextMessage_Fragment: MessagesFields_InterpolatedTextMessage_Fragment;
        };
      })
    | ({ __typename?: 'LocationSearchInputMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_LocationSearchInputMessage_Fragment: MessagesFields_LocationSearchInputMessage_Fragment;
        };
      })
    | ({ __typename?: 'OutletsCardMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_OutletsCardMessage_Fragment: MessagesFields_OutletsCardMessage_Fragment;
        };
      })
    | ({ __typename?: 'ProductCardMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_ProductCardMessage_Fragment: MessagesFields_ProductCardMessage_Fragment;
        };
      })
    | ({ __typename?: 'ProductSearchInputMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_ProductSearchInputMessage_Fragment: MessagesFields_ProductSearchInputMessage_Fragment;
        };
      })
    | ({ __typename?: 'QuickReplyMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_QuickReplyMessage_Fragment: MessagesFields_QuickReplyMessage_Fragment;
        };
      })
    | ({ __typename?: 'SurveyButtonMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_SurveyButtonMessage_Fragment: MessagesFields_SurveyButtonMessage_Fragment;
        };
      })
    | ({ __typename?: 'TextMessage' } & {
        ' $fragmentRefs'?: {
          MessagesFields_TextMessage_Fragment: MessagesFields_TextMessage_Fragment;
        };
      })
  >;
} & { ' $fragmentName'?: 'ApiResponseFieldsFragment' };

export type ExecuteActionMutationVariables = Exact<{
  sessionId: Scalars['String'];
  action: ReplyActionInput;
  params: Scalars['JSON'];
}>;

export type ExecuteActionMutation = {
  __typename?: 'Mutation';
  executeAction: { __typename?: 'BotResponse' } & {
    ' $fragmentRefs'?: { ApiResponseFieldsFragment: ApiResponseFieldsFragment };
  };
};

export type SendMessageMutationVariables = Exact<{
  sessionId: Scalars['String'];
  message: Scalars['String'];
}>;

export type SendMessageMutation = {
  __typename?: 'Mutation';
  sendMessage: { __typename?: 'BotResponse' } & {
    ' $fragmentRefs'?: { ApiResponseFieldsFragment: ApiResponseFieldsFragment };
  };
};

export type StartConversationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StartConversationMutation = {
  __typename?: 'Mutation';
  startConversation: { __typename?: 'BotResponse' } & {
    ' $fragmentRefs'?: { ApiResponseFieldsFragment: ApiResponseFieldsFragment };
  };
};

export type GetProductSuggestionsQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type GetProductSuggestionsQuery = {
  __typename?: 'Query';
  productSuggestions: {
    __typename?: 'ProductSuggestions';
    suggestions: Array<{ __typename?: 'ProductSuggestion'; value: string }>;
  };
};

export type AddFeedbackSurveyMutationVariables = Exact<{
  survey: FeedbackSurveyInput;
}>;

export type AddFeedbackSurveyMutation = {
  __typename?: 'Mutation';
  survey: {
    __typename?: 'FeedbackSurveyResult';
    created: CCA.GraphQLDateTime;
    sessionId: string;
  };
};

export type AddUserAgentMutationVariables = Exact<{
  userAgent: UserAgentInput;
}>;

export type AddUserAgentMutation = {
  __typename?: 'Mutation';
  submitUserAgent: {
    __typename?: 'UserAgentResult';
    created: CCA.GraphQLDateTime;
    sessionId: string;
  };
};

export type FaqWithScoreFieldsFragment = {
  __typename?: 'FaqWithScore';
  score: number;
  faq: {
    __typename?: 'Faq';
    id: number;
    created: number;
    updated: number;
    summary: string;
    keywords: Array<string>;
    question: string;
    answer: string;
    markdownAnswer: string;
    strippedAnswer: string;
    language: string;
    categories: Array<string>;
    shortAnswer?: string | null;
    accessCount: number;
    rank1Count: number;
    rank2Count: number;
    rank3Count: number;
    rank4Count: number;
    rank5Count: number;
    url: string;
  };
} & { ' $fragmentName'?: 'FaqWithScoreFieldsFragment' };

export type OutletWithDistanceFieldsFragment = {
  __typename?: 'OutletWithDistance';
  distance: number;
  outlet: {
    __typename?: 'Outlet';
    id: number;
    displayName: string;
    url?: string | null;
    timezone?: string | null;
    isOpened: boolean;
    address: {
      __typename?: 'OutletAddress';
      city: string;
      emailAddress?: string | null;
      faxNumber?: string | null;
      phoneNumber?: string | null;
      street: string;
      streetNumber?: string | null;
      zipCode: string;
    };
    geolocation: {
      __typename?: 'Geolocation';
      latitude: number;
      longitude: number;
    };
    openingTimes: Array<{
      __typename?: 'OpeningTimeEntry';
      weekDay: number;
      openingTime: string;
      closingTime: string;
    }>;
    openingTimeExceptions: Array<{
      __typename?: 'OpeningTimeExceptionEntry';
      date: string;
      description: string;
    }>;
  };
} & { ' $fragmentName'?: 'OutletWithDistanceFieldsFragment' };

export type PaginationFieldsFragment = {
  __typename?: 'Pagination';
  pageSize: number;
  skipPages: number;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
  totalItems: number;
} & { ' $fragmentName'?: 'PaginationFieldsFragment' };

export type ProductFieldsFragment = {
  __typename?: 'Product';
  id: string;
  name: string;
  url?: string | null;
  imageUrl?: string | null;
  onlineAvailability: OnlineAvailability;
  energyEfficiencyClass?: CCA.GraphQLEnergyEfficiencyClass | null;
  energyEfficiencyClassEu2017_1369?: EnergyEfficiencyClassEu2017_1369 | null;
  price: {
    __typename?: 'ProductPrice';
    salesPrice: number;
    strikePrice?: number | null;
    strikePriceType?: StrikePriceType | null;
    currency: string;
    recommendedRetailPrice?: number | null;
  };
  basePrice?: {
    __typename?: 'ProductBasePrice';
    baseInfo: string;
    unitPrice: number;
  } | null;
  mainFeatures: Array<{
    __typename?: 'ProductMainFeature';
    name: string;
    value: string;
  }>;
  rating?: {
    __typename?: 'ProductRating';
    averageRating?: number | null;
    ratingsCount: number;
  } | null;
} & { ' $fragmentName'?: 'ProductFieldsFragment' };

export type ServiceSiteFieldsFragment = {
  __typename?: 'ServiceSite';
  name: string;
  image: string;
  url: string;
} & { ' $fragmentName'?: 'ServiceSiteFieldsFragment' };

export type BotAvatarMessageFieldsFragment = {
  __typename?: 'BotAvatarMessage';
  id: string;
  scope: string;
  type: string;
  kind: BotAvatarKind;
} & { ' $fragmentName'?: 'BotAvatarMessageFieldsFragment' };

export type CardMessageFieldsFragment = {
  __typename?: 'CardMessage';
  id: string;
  scope: string;
  type: string;
  preventDisable: boolean;
  cardHeading?: string | null;
  cardSubheading?: string | null;
  list?: Array<{
    __typename?: 'ListEntry';
    label?: string | null;
    description?: string | null;
    url?: string | null;
    action?:
      | ({ __typename?: 'ReplyAction' } & {
          ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
        })
      | null;
  }> | null;
} & { ' $fragmentName'?: 'CardMessageFieldsFragment' };

export type ContactCardOpeningsFieldsFragment = {
  __typename?: 'ContactCardOpenings';
  label: string;
  openingTime: string;
  closingTime: string;
} & { ' $fragmentName'?: 'ContactCardOpeningsFieldsFragment' };

export type ContactCardMessageFieldsFragment = {
  __typename?: 'ContactCardMessage';
  id: string;
  scope: string;
  type: string;
  heading: string;
  callInvitationLabel: string;
  availableLabel: string;
  notAvailableLabel: string;
  phoneNumber: string;
  phoneNumberInfo?: string | null;
  timeLabel: string;
  contactFormLabel: string;
  contactFormLink: string;
  weekdays: { __typename?: 'ContactCardOpenings' } & {
    ' $fragmentRefs'?: {
      ContactCardOpeningsFieldsFragment: ContactCardOpeningsFieldsFragment;
    };
  };
  saturday?:
    | ({ __typename?: 'ContactCardOpenings' } & {
        ' $fragmentRefs'?: {
          ContactCardOpeningsFieldsFragment: ContactCardOpeningsFieldsFragment;
        };
      })
    | null;
  holidays?:
    | ({ __typename?: 'ContactCardOpenings' } & {
        ' $fragmentRefs'?: {
          ContactCardOpeningsFieldsFragment: ContactCardOpeningsFieldsFragment;
        };
      })
    | null;
} & { ' $fragmentName'?: 'ContactCardMessageFieldsFragment' };

export type FaqCardMessageFieldsFragment = {
  __typename?: 'FaqCardMessage';
  id: string;
  scope: string;
  type: string;
  serviceSite?:
    | ({ __typename?: 'ServiceSite' } & {
        ' $fragmentRefs'?: {
          ServiceSiteFieldsFragment: ServiceSiteFieldsFragment;
        };
      })
    | null;
  faqWithScore: Array<
    { __typename?: 'FaqWithScore' } & {
      ' $fragmentRefs'?: {
        FaqWithScoreFieldsFragment: FaqWithScoreFieldsFragment;
      };
    }
  >;
} & { ' $fragmentName'?: 'FaqCardMessageFieldsFragment' };

export type FlowFeedbackMessageFieldsFragment = {
  __typename?: 'FlowFeedbackMessage';
  id: string;
  scope: string;
  type: string;
  label: string;
  flowContext: string;
} & { ' $fragmentName'?: 'FlowFeedbackMessageFieldsFragment' };

export type InterpolatedTextMessageFieldsFragment = {
  __typename?: 'InterpolatedTextMessage';
  id: string;
  scope: string;
  type: string;
  text: string;
  interpolations: Array<
    | {
        __typename?: 'EnvironmentInterpolation';
        name: string;
        type: InterpolationType;
      }
    | {
        __typename?: 'TranslationInterpolation';
        name: string;
        type: InterpolationType;
        parameters: Array<{
          __typename?: 'TranslationParameter';
          name: string;
          value: string;
        }>;
      }
  >;
} & { ' $fragmentName'?: 'InterpolatedTextMessageFieldsFragment' };

export type LocationSearchInputMessageFieldsFragment = {
  __typename?: 'LocationSearchInputMessage';
  id: string;
  scope: string;
  type: string;
  placeholder: string;
  enableLocationLabel: string;
  action: { __typename?: 'ReplyAction' } & {
    ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
  };
  locationDefaultFailureAction: { __typename?: 'ReplyAction' } & {
    ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
  };
  locationPermissionFailureAction: { __typename?: 'ReplyAction' } & {
    ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
  };
  locationTimeoutFailureAction: { __typename?: 'ReplyAction' } & {
    ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
  };
} & { ' $fragmentName'?: 'LocationSearchInputMessageFieldsFragment' };

export type OutletsCardMessageFieldsFragment = {
  __typename?: 'OutletsCardMessage';
  id: string;
  scope: string;
  type: string;
  outletsWithDistance: Array<
    { __typename?: 'OutletWithDistance' } & {
      ' $fragmentRefs'?: {
        OutletWithDistanceFieldsFragment: OutletWithDistanceFieldsFragment;
      };
    }
  >;
} & { ' $fragmentName'?: 'OutletsCardMessageFieldsFragment' };

export type ProductCardMessageFieldsFragment = {
  __typename?: 'ProductCardMessage';
  id: string;
  scope: string;
  type: string;
  product: { __typename?: 'Product' } & {
    ' $fragmentRefs'?: { ProductFieldsFragment: ProductFieldsFragment };
  };
  availabilityAction?:
    | ({ __typename?: 'ReplyAction' } & {
        ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
      })
    | null;
} & { ' $fragmentName'?: 'ProductCardMessageFieldsFragment' };

export type ProductSearchInputMessageFieldsFragment = {
  __typename?: 'ProductSearchInputMessage';
  id: string;
  scope: string;
  type: string;
  placeholder: string;
  action: { __typename?: 'ReplyAction' } & {
    ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
  };
} & { ' $fragmentName'?: 'ProductSearchInputMessageFieldsFragment' };

export type QuickReplyMessageFieldsFragment = {
  __typename?: 'QuickReplyMessage';
  id: string;
  scope: string;
  type: string;
  keepPreviousMessagesActive: boolean;
  options: Array<{
    __typename?: 'QuickReplyOption';
    label: string;
    action: { __typename?: 'ReplyAction' } & {
      ' $fragmentRefs'?: { ActionFieldsFragment: ActionFieldsFragment };
    };
  }>;
} & { ' $fragmentName'?: 'QuickReplyMessageFieldsFragment' };

export type SurveyButtonMessageFieldsFragment = {
  __typename?: 'SurveyButtonMessage';
  id: string;
  scope: string;
  type: string;
  label: string;
} & { ' $fragmentName'?: 'SurveyButtonMessageFieldsFragment' };

export type TextMessageFieldsFragment = {
  __typename?: 'TextMessage';
  id: string;
  scope: string;
  type: string;
  text: string;
  intent?: {
    __typename?: 'IntentContext';
    id: string;
    displayName: string;
  } | null;
} & { ' $fragmentName'?: 'TextMessageFieldsFragment' };

type MessagesFields_BotAvatarMessage_Fragment = ({
  __typename: 'BotAvatarMessage';
} & {
  ' $fragmentRefs'?: {
    BotAvatarMessageFieldsFragment: BotAvatarMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_BotAvatarMessage_Fragment' };

type MessagesFields_CardMessage_Fragment = ({ __typename: 'CardMessage' } & {
  ' $fragmentRefs'?: { CardMessageFieldsFragment: CardMessageFieldsFragment };
}) & { ' $fragmentName'?: 'MessagesFields_CardMessage_Fragment' };

type MessagesFields_ContactCardMessage_Fragment = ({
  __typename: 'ContactCardMessage';
} & {
  ' $fragmentRefs'?: {
    ContactCardMessageFieldsFragment: ContactCardMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_ContactCardMessage_Fragment' };

type MessagesFields_FaqCardMessage_Fragment = ({
  __typename: 'FaqCardMessage';
} & {
  ' $fragmentRefs'?: {
    FaqCardMessageFieldsFragment: FaqCardMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_FaqCardMessage_Fragment' };

type MessagesFields_FlowFeedbackMessage_Fragment = ({
  __typename: 'FlowFeedbackMessage';
} & {
  ' $fragmentRefs'?: {
    FlowFeedbackMessageFieldsFragment: FlowFeedbackMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_FlowFeedbackMessage_Fragment' };

type MessagesFields_InterpolatedTextMessage_Fragment = ({
  __typename: 'InterpolatedTextMessage';
} & {
  ' $fragmentRefs'?: {
    InterpolatedTextMessageFieldsFragment: InterpolatedTextMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_InterpolatedTextMessage_Fragment' };

type MessagesFields_LocationSearchInputMessage_Fragment = ({
  __typename: 'LocationSearchInputMessage';
} & {
  ' $fragmentRefs'?: {
    LocationSearchInputMessageFieldsFragment: LocationSearchInputMessageFieldsFragment;
  };
}) & {
  ' $fragmentName'?: 'MessagesFields_LocationSearchInputMessage_Fragment';
};

type MessagesFields_OutletsCardMessage_Fragment = ({
  __typename: 'OutletsCardMessage';
} & {
  ' $fragmentRefs'?: {
    OutletsCardMessageFieldsFragment: OutletsCardMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_OutletsCardMessage_Fragment' };

type MessagesFields_ProductCardMessage_Fragment = ({
  __typename: 'ProductCardMessage';
} & {
  ' $fragmentRefs'?: {
    ProductCardMessageFieldsFragment: ProductCardMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_ProductCardMessage_Fragment' };

type MessagesFields_ProductSearchInputMessage_Fragment = ({
  __typename: 'ProductSearchInputMessage';
} & {
  ' $fragmentRefs'?: {
    ProductSearchInputMessageFieldsFragment: ProductSearchInputMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_ProductSearchInputMessage_Fragment' };

type MessagesFields_QuickReplyMessage_Fragment = ({
  __typename: 'QuickReplyMessage';
} & {
  ' $fragmentRefs'?: {
    QuickReplyMessageFieldsFragment: QuickReplyMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_QuickReplyMessage_Fragment' };

type MessagesFields_SurveyButtonMessage_Fragment = ({
  __typename: 'SurveyButtonMessage';
} & {
  ' $fragmentRefs'?: {
    SurveyButtonMessageFieldsFragment: SurveyButtonMessageFieldsFragment;
  };
}) & { ' $fragmentName'?: 'MessagesFields_SurveyButtonMessage_Fragment' };

type MessagesFields_TextMessage_Fragment = ({ __typename: 'TextMessage' } & {
  ' $fragmentRefs'?: { TextMessageFieldsFragment: TextMessageFieldsFragment };
}) & { ' $fragmentName'?: 'MessagesFields_TextMessage_Fragment' };

export type MessagesFieldsFragment =
  | MessagesFields_BotAvatarMessage_Fragment
  | MessagesFields_CardMessage_Fragment
  | MessagesFields_ContactCardMessage_Fragment
  | MessagesFields_FaqCardMessage_Fragment
  | MessagesFields_FlowFeedbackMessage_Fragment
  | MessagesFields_InterpolatedTextMessage_Fragment
  | MessagesFields_LocationSearchInputMessage_Fragment
  | MessagesFields_OutletsCardMessage_Fragment
  | MessagesFields_ProductCardMessage_Fragment
  | MessagesFields_ProductSearchInputMessage_Fragment
  | MessagesFields_QuickReplyMessage_Fragment
  | MessagesFields_SurveyButtonMessage_Fragment
  | MessagesFields_TextMessage_Fragment;

export type ActionFieldsFragment = {
  __typename?: 'ReplyAction';
  type: DialogflowActionTypes;
  value: string;
} & { ' $fragmentName'?: 'ActionFieldsFragment' };

export type SearchFaqQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  skipPages: Scalars['Int'];
}>;

export type SearchFaqQuery = {
  __typename?: 'Query';
  searchFaq: {
    __typename?: 'FaqSearchResult';
    pagination: { __typename?: 'Pagination' } & {
      ' $fragmentRefs'?: { PaginationFieldsFragment: PaginationFieldsFragment };
    };
    faqsWithScore: Array<
      { __typename?: 'FaqWithScore' } & {
        ' $fragmentRefs'?: {
          FaqWithScoreFieldsFragment: FaqWithScoreFieldsFragment;
        };
      }
    >;
  };
};

export type SearchServiceSiteQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;

export type SearchServiceSiteQuery = {
  __typename?: 'Query';
  searchServiceSites: {
    __typename?: 'ServiceSitesSearchResult';
    serviceSites: Array<{
      __typename?: 'ServiceSiteResult';
      name: string;
      url: string;
    }>;
  };
};

export type FindNearestOutletsQueryVariables = Exact<{
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  skipPages: Scalars['Int'];
}>;

export type FindNearestOutletsQuery = {
  __typename?: 'Query';
  nearestOutlets: {
    __typename?: 'OutletSearchResult';
    pagination: { __typename?: 'Pagination' } & {
      ' $fragmentRefs'?: { PaginationFieldsFragment: PaginationFieldsFragment };
    };
    outletsWithDistance: Array<
      { __typename?: 'OutletWithDistance' } & {
        ' $fragmentRefs'?: {
          OutletWithDistanceFieldsFragment: OutletWithDistanceFieldsFragment;
        };
      }
    >;
  };
};

export type GetProductAvailabilityQueryVariables = Exact<{
  productId: Scalars['String'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
}>;

export type GetProductAvailabilityQuery = {
  __typename?: 'Query';
  productAvailability: {
    __typename?: 'ProductAvailability';
    outletsWithDistance: Array<
      { __typename?: 'OutletWithDistance' } & {
        ' $fragmentRefs'?: {
          OutletWithDistanceFieldsFragment: OutletWithDistanceFieldsFragment;
        };
      }
    >;
    pagination: { __typename?: 'Pagination' } & {
      ' $fragmentRefs'?: { PaginationFieldsFragment: PaginationFieldsFragment };
    };
  };
};

export type SearchProductsQueryVariables = Exact<{
  search: Scalars['String'];
  pageNumber: Scalars['Int'];
}>;

export type SearchProductsQuery = {
  __typename?: 'Query';
  searchProducts: {
    __typename?: 'ProductSearchResult';
    products: Array<
      { __typename?: 'Product' } & {
        ' $fragmentRefs'?: { ProductFieldsFragment: ProductFieldsFragment };
      }
    >;
    pagination: { __typename?: 'Pagination' } & {
      ' $fragmentRefs'?: { PaginationFieldsFragment: PaginationFieldsFragment };
    };
  };
};

export type GetTranslationsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;

export type GetTranslationsQuery = {
  __typename?: 'Query';
  translations: {
    __typename?: 'Translations';
    translations?: CCA.GraphQLJSON | null;
  };
};

export const TextMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextMessageFieldsFragment, unknown>;
export const BotAvatarMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BotAvatarMessageFieldsFragment, unknown>;
export const ActionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionFieldsFragment, unknown>;
export const CardMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CardMessageFieldsFragment, unknown>;
export const QuickReplyMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuickReplyMessageFieldsFragment, unknown>;
export const FlowFeedbackMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlowFeedbackMessageFieldsFragment, unknown>;
export const ContactCardOpeningsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactCardOpeningsFieldsFragment, unknown>;
export const ContactCardMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactCardMessageFieldsFragment, unknown>;
export const ProductSearchInputMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductSearchInputMessageFieldsFragment, unknown>;
export const ProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductFieldsFragment, unknown>;
export const ProductCardMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductCardMessageFieldsFragment, unknown>;
export const LocationSearchInputMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationSearchInputMessageFieldsFragment, unknown>;
export const OutletWithDistanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutletWithDistanceFieldsFragment, unknown>;
export const OutletsCardMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutletsCardMessageFieldsFragment, unknown>;
export const ServiceSiteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServiceSiteFieldsFragment, unknown>;
export const FaqWithScoreFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FaqWithScoreFieldsFragment, unknown>;
export const FaqCardMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FaqCardMessageFieldsFragment, unknown>;
export const InterpolatedTextMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InterpolatedTextMessageFieldsFragment, unknown>;
export const SurveyButtonMessageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SurveyButtonMessageFieldsFragment, unknown>;
export const MessagesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BotAvatarMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'QuickReplyMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ContactCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OutletsCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FaqCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagesFieldsFragment, unknown>;
export const ApiResponseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagesFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BotAvatarMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'QuickReplyMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ContactCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OutletsCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FaqCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiResponseFieldsFragment, unknown>;
export const PaginationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Pagination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaginationFieldsFragment, unknown>;
export const AddClickTrackingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddClickTracking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clickTracking' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClickTrackingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickTracking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clickTracking' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clickTracking' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddClickTrackingMutation,
  AddClickTrackingMutationVariables
>;
export const AddIntentFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddIntentFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedback' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'IntentFeedback' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitIntentFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedback' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedback' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FeedbackResultWithIntent' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddIntentFeedbackMutation,
  AddIntentFeedbackMutationVariables
>;
export const AddFlowFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFlowFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedback' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FlowFeedback' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitFlowFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedback' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedback' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'FeedbackResultWithFlowContext',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFlowFeedbackMutation,
  AddFlowFeedbackMutationVariables
>;
export const SearchLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchLocationQuery, SearchLocationQueryVariables>;
export const ExecuteActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExecuteAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'action' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReplyActionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'executeAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'action' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'queryParams' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'params' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApiResponseFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BotAvatarMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'QuickReplyMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ContactCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OutletsCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FaqCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagesFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExecuteActionMutation,
  ExecuteActionMutationVariables
>;
export const SendMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApiResponseFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BotAvatarMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'QuickReplyMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ContactCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OutletsCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FaqCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagesFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>;
export const StartConversationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartConversation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startConversation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApiResponseFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BotAvatarMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotAvatarMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReplyAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardHeading' },
            name: { kind: 'Name', value: 'heading' },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'cardSubheading' },
            name: { kind: 'Name', value: 'subheading' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preventDisable' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuickReplyMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuickReplyMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActionFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keepPreviousMessagesActive' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FlowFeedbackMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flowContext' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardOpenings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closingTime' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ContactCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heading' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callInvitationLabel' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'availableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notAvailableLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weekdays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saturday' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidays' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ContactCardOpeningsFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFormLink' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilityAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LocationSearchInputMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationDefaultFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationPermissionFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationTimeoutFailureAction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActionFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableLocationLabel' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletsCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletsCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletsWithDistance' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OutletWithDistanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceSiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceSite' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqCardMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqCardMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ServiceSiteFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faqWithScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FaqWithScoreFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InterpolatedTextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'interpolations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TranslationInterpolation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SurveyButtonMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessagesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'TextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BotAvatarMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'QuickReplyMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FlowFeedbackMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ContactCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'LocationSearchInputMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OutletsCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'FaqCardMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InterpolatedTextMessageFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SurveyButtonMessageFields' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiResponseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BotResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MessagesFields' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartConversationMutation,
  StartConversationMutationVariables
>;
export const GetProductSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productSuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductSuggestionsQuery,
  GetProductSuggestionsQueryVariables
>;
export const AddFeedbackSurveyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFeedbackSurvey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'survey' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FeedbackSurveyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'survey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'survey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'survey' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFeedbackSurveyMutation,
  AddFeedbackSurveyMutationVariables
>;
export const AddUserAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userAgent' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserAgentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitUserAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userAgent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userAgent' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUserAgentMutation,
  AddUserAgentMutationVariables
>;
export const SearchFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchFaq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipPages' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFaq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paginationOptions' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipPages' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skipPages' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaginationFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'faqsWithScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FaqWithScoreFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Pagination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FaqWithScoreFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FaqWithScore' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'question' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markdownAnswer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strippedAnswer' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank1Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank2Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank3Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank4Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank5Count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchFaqQuery, SearchFaqQueryVariables>;
export const SearchServiceSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchServiceSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchServiceSites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceSites' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchServiceSiteQuery,
  SearchServiceSiteQueryVariables
>;
export const FindNearestOutletsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindNearestOutlets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'longitude' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'latitude' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'skipPages' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nearestOutlets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geolocation' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'longitude' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latitude' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paginationOptions' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipPages' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skipPages' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaginationFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletsWithDistance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OutletWithDistanceFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Pagination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindNearestOutletsQuery,
  FindNearestOutletsQueryVariables
>;
export const GetProductAvailabilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'longitude' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'latitude' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAvailability' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geolocation' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'latitude' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'longitude' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paginationOptions' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipPages' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletsWithDistance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OutletWithDistanceFields',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaginationFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OutletWithDistanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OutletWithDistance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faxNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'streetNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geolocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weekDay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'openingTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closingTime' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimeExceptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpened' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Pagination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductAvailabilityQuery,
  GetProductAvailabilityQueryVariables
>;
export const SearchProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paginationOptions' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipPages' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pageNumber' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaginationFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onlineAvailability' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClass' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'energyEfficiencyClassEu2017_1369' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'salesPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'strikePrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'strikePriceType' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedRetailPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'baseInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitPrice' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mainFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ratingsCount' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaginationFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Pagination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skipPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProductsQuery, SearchProductsQueryVariables>;
export const GetTranslationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTranslations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'translations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lang' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lang' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'translations' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTranslationsQuery,
  GetTranslationsQueryVariables
>;
