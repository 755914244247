import { ComponentType, ForwardedRef } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Button, ButtonProps } from '../../button';
import { CardBody, CardDivider, CardHeader } from '../../card';
import { Heading } from '../../heading';
import { OpeningTime } from '../../opening-times';
import OutletLocationMap, {
  OutletLocationMapProps,
} from '../outlet-location-map/OutletLocationMap';

import AddressEntry, { AddressEntryProps } from './entries/AddressEntry';
import CityEntry, { CityEntryProps } from './entries/CityEntry';
import OpeningTimeEntry, {
  OpeningTimeEntryProps,
} from './entries/OpeningTimeEntry';
import PhoneEntry, { PhoneEntryProps } from './entries/PhoneEntry';

export type OutletDetailProps = {
  heading: string;
  address: string;
  phoneNumber?: string;
  openingTimes: OpeningTime[];
  openingTimeExceptionMessage?: string;
  url?: string;
  city: string;
  distance: number;
  isOpened: boolean;
  openedLabel: string;
  closedLabel: string;
  showAllInformationLabel: string;
  mapImageUrl: string;
  onBackClick?: () => void;
  onOpenUrl?: () => void;
  showOutletStatus?: boolean;
  CityEntryComponent?: ComponentType<CityEntryProps>;
  AddressEntryComponent?: ComponentType<AddressEntryProps>;
  PhoneEntryComponent?: ComponentType<PhoneEntryProps>;
  OpeningTimeEntryComponent?: ComponentType<OpeningTimeEntryProps>;
  OutletLocationMapComponent?: ComponentType<OutletLocationMapProps>;
  ButtonComponent?: ComponentType<ButtonProps>;
};
const OutletDetail = (
  {
    heading,
    address,
    phoneNumber,
    openingTimes,
    openingTimeExceptionMessage,
    url,
    city,
    distance,
    isOpened,
    openedLabel,
    closedLabel,
    showAllInformationLabel,
    mapImageUrl,
    onBackClick = () => {},
    onOpenUrl = () => {},
    showOutletStatus = true,
    CityEntryComponent = CityEntry,
    AddressEntryComponent = AddressEntry,
    PhoneEntryComponent = PhoneEntry,
    OpeningTimeEntryComponent = OpeningTimeEntry,
    OutletLocationMapComponent = OutletLocationMap,
    ButtonComponent = Button,
  }: OutletDetailProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Box ref={ref}>
      <CardHeader showBackIcon onBackClick={onBackClick}>
        <Heading>{heading}</Heading>
      </CardHeader>
      <CardBody>
        <CityEntryComponent
          distance={distance}
          city={city}
          showOutletStatus={showOutletStatus}
          isOpened={isOpened}
          openedLabel={openedLabel}
          closedLabel={closedLabel}
        />
        <AddressEntryComponent address={address} />
        {phoneNumber && <PhoneEntryComponent phoneNumber={phoneNumber} />}
        <OpeningTimeEntryComponent
          openingTimes={openingTimes}
          openingTimeExceptionMessage={openingTimeExceptionMessage}
        />
      </CardBody>
      <CardDivider />
      <CardBody>
        <Box
          sx={{
            paddingBottom: '15px',
          }}
        >
          <OutletLocationMapComponent src={mapImageUrl} />
        </Box>
        {url && (
          <ButtonComponent
            fullWidth
            color="secondary"
            inverted
            onClick={() => {
              window.open(url, '_blank');
              onOpenUrl();
            }}
          >
            {showAllInformationLabel}
          </ButtonComponent>
        )}
      </CardBody>
    </Box>
  );
};

export default forwardRefWithDisplayName(OutletDetail, 'OutletDetail');
