/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation AddClickTracking($clickTracking: ClickTrackingInput!) {\n    clickTracking(clickTracking: $clickTracking) {\n      id\n      created\n    }\n  }\n':
    types.AddClickTrackingDocument,
  '\n  mutation AddIntentFeedback($feedback: IntentFeedback!) {\n    submitIntentFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithIntent {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n':
    types.AddIntentFeedbackDocument,
  '\n  mutation AddFlowFeedback($feedback: FlowFeedback!) {\n    submitFlowFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithFlowContext {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n':
    types.AddFlowFeedbackDocument,
  '\n  query SearchLocation($searchTerm: String!, $sessionId: String!) {\n    searchLocation(searchTerm: $searchTerm, sessionId: $sessionId) {\n      suggestions {\n        id\n        label\n      }\n    }\n  }\n':
    types.SearchLocationDocument,
  '\n  fragment ApiResponseFields on BotResponse {\n    id\n    sessionId\n    messages {\n      ...MessagesFields\n    }\n  }\n':
    types.ApiResponseFieldsFragmentDoc,
  '\n  mutation ExecuteAction(\n    $sessionId: String!\n    $action: ReplyActionInput!\n    $params: JSON!\n  ) {\n    executeAction(\n      sessionId: $sessionId\n      action: $action\n      queryParams: { parameters: $params }\n    ) {\n      ...ApiResponseFields\n    }\n  }\n':
    types.ExecuteActionDocument,
  '\n  mutation SendMessage($sessionId: String!, $message: String!) {\n    sendMessage(sessionId: $sessionId, message: $message) {\n      ...ApiResponseFields\n    }\n  }\n':
    types.SendMessageDocument,
  '\n  mutation StartConversation {\n    startConversation {\n      ...ApiResponseFields\n    }\n  }\n':
    types.StartConversationDocument,
  '\n  query GetProductSuggestions($query: String!) {\n    productSuggestions(query: $query) {\n      suggestions {\n        value\n      }\n    }\n  }\n':
    types.GetProductSuggestionsDocument,
  '\n  mutation AddFeedbackSurvey($survey: FeedbackSurveyInput!) {\n    survey(survey: $survey) {\n      created\n      sessionId\n    }\n  }\n':
    types.AddFeedbackSurveyDocument,
  '\n  mutation AddUserAgent($userAgent: UserAgentInput!) {\n    submitUserAgent(userAgent: $userAgent) {\n      created\n      sessionId\n    }\n  }\n':
    types.AddUserAgentDocument,
  '\n  fragment FaqWithScoreFields on FaqWithScore {\n    score\n    faq {\n      id\n      created\n      updated\n      summary\n      keywords\n      question\n      answer\n      markdownAnswer\n      strippedAnswer\n      language\n      categories\n      shortAnswer\n      accessCount\n      rank1Count\n      rank2Count\n      rank3Count\n      rank4Count\n      rank5Count\n      url\n    }\n  }\n':
    types.FaqWithScoreFieldsFragmentDoc,
  '\n  fragment OutletWithDistanceFields on OutletWithDistance {\n    distance\n    outlet {\n      id\n      displayName\n      url\n      timezone\n      address {\n        city\n        emailAddress\n        faxNumber\n        phoneNumber\n        street\n        streetNumber\n        zipCode\n      }\n      geolocation {\n        latitude\n        longitude\n      }\n      openingTimes {\n        weekDay\n        openingTime\n        closingTime\n      }\n      openingTimeExceptions {\n        date\n        description\n      }\n      isOpened\n    }\n  }\n':
    types.OutletWithDistanceFieldsFragmentDoc,
  '\n  fragment PaginationFields on Pagination {\n    pageSize\n    skipPages\n    currentPage\n    totalPages\n    hasNextPage\n    totalItems\n  }\n':
    types.PaginationFieldsFragmentDoc,
  '\n  fragment ProductFields on Product {\n    id\n    name\n    url\n    imageUrl\n    onlineAvailability\n    energyEfficiencyClass\n    energyEfficiencyClassEu2017_1369\n    price {\n      salesPrice\n      strikePrice\n      strikePriceType\n      currency\n      recommendedRetailPrice\n    }\n    basePrice {\n      baseInfo\n      unitPrice\n    }\n    mainFeatures {\n      name\n      value\n    }\n    rating {\n      averageRating\n      ratingsCount\n    }\n  }\n':
    types.ProductFieldsFragmentDoc,
  '\n  fragment ServiceSiteFields on ServiceSite {\n    name\n    image\n    url\n  }\n':
    types.ServiceSiteFieldsFragmentDoc,
  '\n  fragment BotAvatarMessageFields on BotAvatarMessage {\n    id\n    scope\n    type\n    kind\n  }\n':
    types.BotAvatarMessageFieldsFragmentDoc,
  '\n  fragment CardMessageFields on CardMessage {\n    id\n    scope\n    type\n    #   TODO: the problem here is that there are many message types which have heading. on gql union types the types\n    #   TODO: must match if we do not want to define prop aliases\n    cardHeading: heading\n    cardSubheading: subheading\n    list {\n      action {\n        ...ActionFields\n      }\n      label\n      description\n      url\n    }\n    preventDisable\n  }\n':
    types.CardMessageFieldsFragmentDoc,
  '\n  fragment ContactCardOpeningsFields on ContactCardOpenings {\n    label\n    openingTime\n    closingTime\n  }\n':
    types.ContactCardOpeningsFieldsFragmentDoc,
  '\n  fragment ContactCardMessageFields on ContactCardMessage {\n    id\n    scope\n    type\n    heading\n    callInvitationLabel\n    availableLabel\n    notAvailableLabel\n    phoneNumber\n    phoneNumberInfo\n    timeLabel\n    weekdays {\n      ...ContactCardOpeningsFields\n    }\n    saturday {\n      ...ContactCardOpeningsFields\n    }\n    holidays {\n      ...ContactCardOpeningsFields\n    }\n    contactFormLabel\n    contactFormLink\n  }\n':
    types.ContactCardMessageFieldsFragmentDoc,
  '\n  fragment FaqCardMessageFields on FaqCardMessage {\n    id\n    scope\n    type\n    serviceSite {\n      ...ServiceSiteFields\n    }\n    faqWithScore {\n      ...FaqWithScoreFields\n    }\n  }\n':
    types.FaqCardMessageFieldsFragmentDoc,
  '\n  fragment FlowFeedbackMessageFields on FlowFeedbackMessage {\n    id\n    scope\n    type\n    label\n    flowContext\n  }\n':
    types.FlowFeedbackMessageFieldsFragmentDoc,
  '\n  fragment InterpolatedTextMessageFields on InterpolatedTextMessage {\n    id\n    scope\n    type\n    text\n    interpolations {\n      name\n      type\n\n      ... on TranslationInterpolation {\n        parameters {\n          name\n          value\n        }\n      }\n    }\n  }\n':
    types.InterpolatedTextMessageFieldsFragmentDoc,
  '\n  fragment LocationSearchInputMessageFields on LocationSearchInputMessage {\n    id\n    scope\n    type\n    action {\n      ...ActionFields\n    }\n    locationDefaultFailureAction {\n      ...ActionFields\n    }\n    locationPermissionFailureAction {\n      ...ActionFields\n    }\n    locationTimeoutFailureAction {\n      ...ActionFields\n    }\n    placeholder\n    enableLocationLabel\n  }\n':
    types.LocationSearchInputMessageFieldsFragmentDoc,
  '\n  fragment OutletsCardMessageFields on OutletsCardMessage {\n    id\n    scope\n    type\n    outletsWithDistance {\n      ...OutletWithDistanceFields\n    }\n  }\n':
    types.OutletsCardMessageFieldsFragmentDoc,
  '\n  fragment ProductCardMessageFields on ProductCardMessage {\n    id\n    scope\n    type\n    product {\n      ...ProductFields\n    }\n    availabilityAction {\n      ...ActionFields\n    }\n  }\n':
    types.ProductCardMessageFieldsFragmentDoc,
  '\n  fragment ProductSearchInputMessageFields on ProductSearchInputMessage {\n    id\n    scope\n    type\n    placeholder\n    action {\n      ...ActionFields\n    }\n  }\n':
    types.ProductSearchInputMessageFieldsFragmentDoc,
  '\n  fragment QuickReplyMessageFields on QuickReplyMessage {\n    id\n    scope\n    type\n    options {\n      action {\n        ...ActionFields\n      }\n      label\n    }\n    keepPreviousMessagesActive\n  }\n':
    types.QuickReplyMessageFieldsFragmentDoc,
  '\n  fragment SurveyButtonMessageFields on SurveyButtonMessage {\n    id\n    scope\n    type\n    label\n  }\n':
    types.SurveyButtonMessageFieldsFragmentDoc,
  '\n  fragment TextMessageFields on TextMessage {\n    id\n    scope\n    type\n    text\n    intent {\n      id\n      displayName\n    }\n  }\n':
    types.TextMessageFieldsFragmentDoc,
  '\n  fragment MessagesFields on Message {\n    __typename\n\n    ...TextMessageFields\n    ...BotAvatarMessageFields\n    ...CardMessageFields\n    ...QuickReplyMessageFields\n    ...FlowFeedbackMessageFields\n    ...ContactCardMessageFields\n    ...ProductSearchInputMessageFields\n    ...ProductCardMessageFields\n    ...LocationSearchInputMessageFields\n    ...OutletsCardMessageFields\n    ...FaqCardMessageFields\n    ...InterpolatedTextMessageFields\n    ...SurveyButtonMessageFields\n  }\n':
    types.MessagesFieldsFragmentDoc,
  '\n  fragment ActionFields on ReplyAction {\n    type\n    value\n  }\n':
    types.ActionFieldsFragmentDoc,
  '\n  query SearchFaq($searchTerm: String!, $skipPages: Int!) {\n    searchFaq(\n      searchTerm: $searchTerm\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      faqsWithScore {\n        ...FaqWithScoreFields\n      }\n    }\n  }\n':
    types.SearchFaqDocument,
  '\n  query SearchServiceSite($searchTerm: String!) {\n    searchServiceSites(query: $searchTerm) {\n      serviceSites {\n        name\n        url\n      }\n    }\n  }\n':
    types.SearchServiceSiteDocument,
  '\n  query FindNearestOutlets(\n    $longitude: Float!\n    $latitude: Float!\n    $skipPages: Int!\n  ) {\n    nearestOutlets(\n      geolocation: { longitude: $longitude, latitude: $latitude }\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n    }\n  }\n':
    types.FindNearestOutletsDocument,
  '\n  query GetProductAvailability(\n    $productId: String!\n    $longitude: Float!\n    $latitude: Float!\n  ) {\n    productAvailability(\n      productId: $productId\n      geolocation: { latitude: $latitude, longitude: $longitude }\n      paginationOptions: { pageSize: 3, skipPages: 0 }\n    ) {\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n':
    types.GetProductAvailabilityDocument,
  '\n  query SearchProducts($search: String!, $pageNumber: Int!) {\n    searchProducts(\n      query: $search\n      paginationOptions: { pageSize: 3, skipPages: $pageNumber }\n    ) {\n      products {\n        ...ProductFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n':
    types.SearchProductsDocument,
  '\n  query GetTranslations($lang: String!) {\n    translations(lang: $lang) {\n      translations\n    }\n  }\n':
    types.GetTranslationsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddClickTracking($clickTracking: ClickTrackingInput!) {\n    clickTracking(clickTracking: $clickTracking) {\n      id\n      created\n    }\n  }\n',
): (typeof documents)['\n  mutation AddClickTracking($clickTracking: ClickTrackingInput!) {\n    clickTracking(clickTracking: $clickTracking) {\n      id\n      created\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddIntentFeedback($feedback: IntentFeedback!) {\n    submitIntentFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithIntent {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddIntentFeedback($feedback: IntentFeedback!) {\n    submitIntentFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithIntent {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddFlowFeedback($feedback: FlowFeedback!) {\n    submitFlowFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithFlowContext {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddFlowFeedback($feedback: FlowFeedback!) {\n    submitFlowFeedback(feedback: $feedback) {\n      ... on FeedbackResultWithFlowContext {\n        created\n        sessionId\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchLocation($searchTerm: String!, $sessionId: String!) {\n    searchLocation(searchTerm: $searchTerm, sessionId: $sessionId) {\n      suggestions {\n        id\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchLocation($searchTerm: String!, $sessionId: String!) {\n    searchLocation(searchTerm: $searchTerm, sessionId: $sessionId) {\n      suggestions {\n        id\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ApiResponseFields on BotResponse {\n    id\n    sessionId\n    messages {\n      ...MessagesFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ApiResponseFields on BotResponse {\n    id\n    sessionId\n    messages {\n      ...MessagesFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ExecuteAction(\n    $sessionId: String!\n    $action: ReplyActionInput!\n    $params: JSON!\n  ) {\n    executeAction(\n      sessionId: $sessionId\n      action: $action\n      queryParams: { parameters: $params }\n    ) {\n      ...ApiResponseFields\n    }\n  }\n',
): (typeof documents)['\n  mutation ExecuteAction(\n    $sessionId: String!\n    $action: ReplyActionInput!\n    $params: JSON!\n  ) {\n    executeAction(\n      sessionId: $sessionId\n      action: $action\n      queryParams: { parameters: $params }\n    ) {\n      ...ApiResponseFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SendMessage($sessionId: String!, $message: String!) {\n    sendMessage(sessionId: $sessionId, message: $message) {\n      ...ApiResponseFields\n    }\n  }\n',
): (typeof documents)['\n  mutation SendMessage($sessionId: String!, $message: String!) {\n    sendMessage(sessionId: $sessionId, message: $message) {\n      ...ApiResponseFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation StartConversation {\n    startConversation {\n      ...ApiResponseFields\n    }\n  }\n',
): (typeof documents)['\n  mutation StartConversation {\n    startConversation {\n      ...ApiResponseFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductSuggestions($query: String!) {\n    productSuggestions(query: $query) {\n      suggestions {\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetProductSuggestions($query: String!) {\n    productSuggestions(query: $query) {\n      suggestions {\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddFeedbackSurvey($survey: FeedbackSurveyInput!) {\n    survey(survey: $survey) {\n      created\n      sessionId\n    }\n  }\n',
): (typeof documents)['\n  mutation AddFeedbackSurvey($survey: FeedbackSurveyInput!) {\n    survey(survey: $survey) {\n      created\n      sessionId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddUserAgent($userAgent: UserAgentInput!) {\n    submitUserAgent(userAgent: $userAgent) {\n      created\n      sessionId\n    }\n  }\n',
): (typeof documents)['\n  mutation AddUserAgent($userAgent: UserAgentInput!) {\n    submitUserAgent(userAgent: $userAgent) {\n      created\n      sessionId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FaqWithScoreFields on FaqWithScore {\n    score\n    faq {\n      id\n      created\n      updated\n      summary\n      keywords\n      question\n      answer\n      markdownAnswer\n      strippedAnswer\n      language\n      categories\n      shortAnswer\n      accessCount\n      rank1Count\n      rank2Count\n      rank3Count\n      rank4Count\n      rank5Count\n      url\n    }\n  }\n',
): (typeof documents)['\n  fragment FaqWithScoreFields on FaqWithScore {\n    score\n    faq {\n      id\n      created\n      updated\n      summary\n      keywords\n      question\n      answer\n      markdownAnswer\n      strippedAnswer\n      language\n      categories\n      shortAnswer\n      accessCount\n      rank1Count\n      rank2Count\n      rank3Count\n      rank4Count\n      rank5Count\n      url\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OutletWithDistanceFields on OutletWithDistance {\n    distance\n    outlet {\n      id\n      displayName\n      url\n      timezone\n      address {\n        city\n        emailAddress\n        faxNumber\n        phoneNumber\n        street\n        streetNumber\n        zipCode\n      }\n      geolocation {\n        latitude\n        longitude\n      }\n      openingTimes {\n        weekDay\n        openingTime\n        closingTime\n      }\n      openingTimeExceptions {\n        date\n        description\n      }\n      isOpened\n    }\n  }\n',
): (typeof documents)['\n  fragment OutletWithDistanceFields on OutletWithDistance {\n    distance\n    outlet {\n      id\n      displayName\n      url\n      timezone\n      address {\n        city\n        emailAddress\n        faxNumber\n        phoneNumber\n        street\n        streetNumber\n        zipCode\n      }\n      geolocation {\n        latitude\n        longitude\n      }\n      openingTimes {\n        weekDay\n        openingTime\n        closingTime\n      }\n      openingTimeExceptions {\n        date\n        description\n      }\n      isOpened\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PaginationFields on Pagination {\n    pageSize\n    skipPages\n    currentPage\n    totalPages\n    hasNextPage\n    totalItems\n  }\n',
): (typeof documents)['\n  fragment PaginationFields on Pagination {\n    pageSize\n    skipPages\n    currentPage\n    totalPages\n    hasNextPage\n    totalItems\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProductFields on Product {\n    id\n    name\n    url\n    imageUrl\n    onlineAvailability\n    energyEfficiencyClass\n    energyEfficiencyClassEu2017_1369\n    price {\n      salesPrice\n      strikePrice\n      strikePriceType\n      currency\n      recommendedRetailPrice\n    }\n    basePrice {\n      baseInfo\n      unitPrice\n    }\n    mainFeatures {\n      name\n      value\n    }\n    rating {\n      averageRating\n      ratingsCount\n    }\n  }\n',
): (typeof documents)['\n  fragment ProductFields on Product {\n    id\n    name\n    url\n    imageUrl\n    onlineAvailability\n    energyEfficiencyClass\n    energyEfficiencyClassEu2017_1369\n    price {\n      salesPrice\n      strikePrice\n      strikePriceType\n      currency\n      recommendedRetailPrice\n    }\n    basePrice {\n      baseInfo\n      unitPrice\n    }\n    mainFeatures {\n      name\n      value\n    }\n    rating {\n      averageRating\n      ratingsCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ServiceSiteFields on ServiceSite {\n    name\n    image\n    url\n  }\n',
): (typeof documents)['\n  fragment ServiceSiteFields on ServiceSite {\n    name\n    image\n    url\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BotAvatarMessageFields on BotAvatarMessage {\n    id\n    scope\n    type\n    kind\n  }\n',
): (typeof documents)['\n  fragment BotAvatarMessageFields on BotAvatarMessage {\n    id\n    scope\n    type\n    kind\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CardMessageFields on CardMessage {\n    id\n    scope\n    type\n    #   TODO: the problem here is that there are many message types which have heading. on gql union types the types\n    #   TODO: must match if we do not want to define prop aliases\n    cardHeading: heading\n    cardSubheading: subheading\n    list {\n      action {\n        ...ActionFields\n      }\n      label\n      description\n      url\n    }\n    preventDisable\n  }\n',
): (typeof documents)['\n  fragment CardMessageFields on CardMessage {\n    id\n    scope\n    type\n    #   TODO: the problem here is that there are many message types which have heading. on gql union types the types\n    #   TODO: must match if we do not want to define prop aliases\n    cardHeading: heading\n    cardSubheading: subheading\n    list {\n      action {\n        ...ActionFields\n      }\n      label\n      description\n      url\n    }\n    preventDisable\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactCardOpeningsFields on ContactCardOpenings {\n    label\n    openingTime\n    closingTime\n  }\n',
): (typeof documents)['\n  fragment ContactCardOpeningsFields on ContactCardOpenings {\n    label\n    openingTime\n    closingTime\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContactCardMessageFields on ContactCardMessage {\n    id\n    scope\n    type\n    heading\n    callInvitationLabel\n    availableLabel\n    notAvailableLabel\n    phoneNumber\n    phoneNumberInfo\n    timeLabel\n    weekdays {\n      ...ContactCardOpeningsFields\n    }\n    saturday {\n      ...ContactCardOpeningsFields\n    }\n    holidays {\n      ...ContactCardOpeningsFields\n    }\n    contactFormLabel\n    contactFormLink\n  }\n',
): (typeof documents)['\n  fragment ContactCardMessageFields on ContactCardMessage {\n    id\n    scope\n    type\n    heading\n    callInvitationLabel\n    availableLabel\n    notAvailableLabel\n    phoneNumber\n    phoneNumberInfo\n    timeLabel\n    weekdays {\n      ...ContactCardOpeningsFields\n    }\n    saturday {\n      ...ContactCardOpeningsFields\n    }\n    holidays {\n      ...ContactCardOpeningsFields\n    }\n    contactFormLabel\n    contactFormLink\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FaqCardMessageFields on FaqCardMessage {\n    id\n    scope\n    type\n    serviceSite {\n      ...ServiceSiteFields\n    }\n    faqWithScore {\n      ...FaqWithScoreFields\n    }\n  }\n',
): (typeof documents)['\n  fragment FaqCardMessageFields on FaqCardMessage {\n    id\n    scope\n    type\n    serviceSite {\n      ...ServiceSiteFields\n    }\n    faqWithScore {\n      ...FaqWithScoreFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FlowFeedbackMessageFields on FlowFeedbackMessage {\n    id\n    scope\n    type\n    label\n    flowContext\n  }\n',
): (typeof documents)['\n  fragment FlowFeedbackMessageFields on FlowFeedbackMessage {\n    id\n    scope\n    type\n    label\n    flowContext\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment InterpolatedTextMessageFields on InterpolatedTextMessage {\n    id\n    scope\n    type\n    text\n    interpolations {\n      name\n      type\n\n      ... on TranslationInterpolation {\n        parameters {\n          name\n          value\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment InterpolatedTextMessageFields on InterpolatedTextMessage {\n    id\n    scope\n    type\n    text\n    interpolations {\n      name\n      type\n\n      ... on TranslationInterpolation {\n        parameters {\n          name\n          value\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LocationSearchInputMessageFields on LocationSearchInputMessage {\n    id\n    scope\n    type\n    action {\n      ...ActionFields\n    }\n    locationDefaultFailureAction {\n      ...ActionFields\n    }\n    locationPermissionFailureAction {\n      ...ActionFields\n    }\n    locationTimeoutFailureAction {\n      ...ActionFields\n    }\n    placeholder\n    enableLocationLabel\n  }\n',
): (typeof documents)['\n  fragment LocationSearchInputMessageFields on LocationSearchInputMessage {\n    id\n    scope\n    type\n    action {\n      ...ActionFields\n    }\n    locationDefaultFailureAction {\n      ...ActionFields\n    }\n    locationPermissionFailureAction {\n      ...ActionFields\n    }\n    locationTimeoutFailureAction {\n      ...ActionFields\n    }\n    placeholder\n    enableLocationLabel\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OutletsCardMessageFields on OutletsCardMessage {\n    id\n    scope\n    type\n    outletsWithDistance {\n      ...OutletWithDistanceFields\n    }\n  }\n',
): (typeof documents)['\n  fragment OutletsCardMessageFields on OutletsCardMessage {\n    id\n    scope\n    type\n    outletsWithDistance {\n      ...OutletWithDistanceFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProductCardMessageFields on ProductCardMessage {\n    id\n    scope\n    type\n    product {\n      ...ProductFields\n    }\n    availabilityAction {\n      ...ActionFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ProductCardMessageFields on ProductCardMessage {\n    id\n    scope\n    type\n    product {\n      ...ProductFields\n    }\n    availabilityAction {\n      ...ActionFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProductSearchInputMessageFields on ProductSearchInputMessage {\n    id\n    scope\n    type\n    placeholder\n    action {\n      ...ActionFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ProductSearchInputMessageFields on ProductSearchInputMessage {\n    id\n    scope\n    type\n    placeholder\n    action {\n      ...ActionFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment QuickReplyMessageFields on QuickReplyMessage {\n    id\n    scope\n    type\n    options {\n      action {\n        ...ActionFields\n      }\n      label\n    }\n    keepPreviousMessagesActive\n  }\n',
): (typeof documents)['\n  fragment QuickReplyMessageFields on QuickReplyMessage {\n    id\n    scope\n    type\n    options {\n      action {\n        ...ActionFields\n      }\n      label\n    }\n    keepPreviousMessagesActive\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SurveyButtonMessageFields on SurveyButtonMessage {\n    id\n    scope\n    type\n    label\n  }\n',
): (typeof documents)['\n  fragment SurveyButtonMessageFields on SurveyButtonMessage {\n    id\n    scope\n    type\n    label\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TextMessageFields on TextMessage {\n    id\n    scope\n    type\n    text\n    intent {\n      id\n      displayName\n    }\n  }\n',
): (typeof documents)['\n  fragment TextMessageFields on TextMessage {\n    id\n    scope\n    type\n    text\n    intent {\n      id\n      displayName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment MessagesFields on Message {\n    __typename\n\n    ...TextMessageFields\n    ...BotAvatarMessageFields\n    ...CardMessageFields\n    ...QuickReplyMessageFields\n    ...FlowFeedbackMessageFields\n    ...ContactCardMessageFields\n    ...ProductSearchInputMessageFields\n    ...ProductCardMessageFields\n    ...LocationSearchInputMessageFields\n    ...OutletsCardMessageFields\n    ...FaqCardMessageFields\n    ...InterpolatedTextMessageFields\n    ...SurveyButtonMessageFields\n  }\n',
): (typeof documents)['\n  fragment MessagesFields on Message {\n    __typename\n\n    ...TextMessageFields\n    ...BotAvatarMessageFields\n    ...CardMessageFields\n    ...QuickReplyMessageFields\n    ...FlowFeedbackMessageFields\n    ...ContactCardMessageFields\n    ...ProductSearchInputMessageFields\n    ...ProductCardMessageFields\n    ...LocationSearchInputMessageFields\n    ...OutletsCardMessageFields\n    ...FaqCardMessageFields\n    ...InterpolatedTextMessageFields\n    ...SurveyButtonMessageFields\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActionFields on ReplyAction {\n    type\n    value\n  }\n',
): (typeof documents)['\n  fragment ActionFields on ReplyAction {\n    type\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchFaq($searchTerm: String!, $skipPages: Int!) {\n    searchFaq(\n      searchTerm: $searchTerm\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      faqsWithScore {\n        ...FaqWithScoreFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchFaq($searchTerm: String!, $skipPages: Int!) {\n    searchFaq(\n      searchTerm: $searchTerm\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      faqsWithScore {\n        ...FaqWithScoreFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchServiceSite($searchTerm: String!) {\n    searchServiceSites(query: $searchTerm) {\n      serviceSites {\n        name\n        url\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchServiceSite($searchTerm: String!) {\n    searchServiceSites(query: $searchTerm) {\n      serviceSites {\n        name\n        url\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindNearestOutlets(\n    $longitude: Float!\n    $latitude: Float!\n    $skipPages: Int!\n  ) {\n    nearestOutlets(\n      geolocation: { longitude: $longitude, latitude: $latitude }\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindNearestOutlets(\n    $longitude: Float!\n    $latitude: Float!\n    $skipPages: Int!\n  ) {\n    nearestOutlets(\n      geolocation: { longitude: $longitude, latitude: $latitude }\n      paginationOptions: { pageSize: 3, skipPages: $skipPages }\n    ) {\n      pagination {\n        ...PaginationFields\n      }\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductAvailability(\n    $productId: String!\n    $longitude: Float!\n    $latitude: Float!\n  ) {\n    productAvailability(\n      productId: $productId\n      geolocation: { latitude: $latitude, longitude: $longitude }\n      paginationOptions: { pageSize: 3, skipPages: 0 }\n    ) {\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetProductAvailability(\n    $productId: String!\n    $longitude: Float!\n    $latitude: Float!\n  ) {\n    productAvailability(\n      productId: $productId\n      geolocation: { latitude: $latitude, longitude: $longitude }\n      paginationOptions: { pageSize: 3, skipPages: 0 }\n    ) {\n      outletsWithDistance {\n        ...OutletWithDistanceFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchProducts($search: String!, $pageNumber: Int!) {\n    searchProducts(\n      query: $search\n      paginationOptions: { pageSize: 3, skipPages: $pageNumber }\n    ) {\n      products {\n        ...ProductFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchProducts($search: String!, $pageNumber: Int!) {\n    searchProducts(\n      query: $search\n      paginationOptions: { pageSize: 3, skipPages: $pageNumber }\n    ) {\n      products {\n        ...ProductFields\n      }\n      pagination {\n        ...PaginationFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetTranslations($lang: String!) {\n    translations(lang: $lang) {\n      translations\n    }\n  }\n',
): (typeof documents)['\n  query GetTranslations($lang: String!) {\n    translations(lang: $lang) {\n      translations\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
