/** @jsxImportSource theme-ui */
import { ForwardedRef, Fragment, useState } from 'react';
import { Box, Flex } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Button } from '../../button';
import { ArrowIcon } from '../../icons';
import { Text } from '../../text';
import Themed from '../../utility/themed/Themed';

export interface AccordionListItemProps {
  label: string;
  content: string;
  link: string;
  faqButtonLabel: string;
  onClick?: () => void;
}

const AccordionListItem = (
  {
    label,
    content,
    link,
    faqButtonLabel,
    onClick = () => {},
  }: AccordionListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <li
      ref={ref}
      sx={{
        variant: 'layout.listItem',
        ...(open
          ? { backgroundColor: 'grey1' }
          : {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'grey1',
              },
            }),
      }}
      onClick={() => {
        setOpen(!open);
        onClick();
      }}
    >
      <Flex
        sx={{
          alignItems: 'start',
          justifyContent: 'space-between',
        }}
        as="span"
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flex: '0 1 auto',
          }}
          as="span"
        >
          <Fragment>
            <Themed seSx={{ variant: 'layout.prominentFix' }}>
              <Text
                styles={{ fontWeight: 'semiBold', fontFamily: 'prominent' }}
              >
                {label}
              </Text>
            </Themed>
            <Box
              sx={{
                maxHeight: open ? 'auto' : 0,
                overflow: 'auto',
              }}
            >
              <Text>{content}</Text>
              <Box sx={{ mt: 2 }}>
                <Button
                  outlined
                  size="small"
                  onClick={() => window.open(link, '_blank')}
                >
                  {faqButtonLabel}
                </Button>
              </Box>
            </Box>
          </Fragment>
        </Flex>
        <Flex sx={{ flex: '0 0 auto', mt: 2 }}>
          <ArrowIcon orientation={open ? Position.Up : Position.Down} />
        </Flex>
      </Flex>
    </li>
  );
};

export default forwardRefWithDisplayName(
  AccordionListItem,
  'AccordionListItem',
);
