import { BotName, Locale, SalesLineLabel, TimeZone } from '@cca/util';

import Environment from '../../Environment.type';

const environment: Environment = {
  isDevelopment: false,
  isQa: true,
  isProduction: false,

  theme: 'mmes',
  middlewareUrl: `${window.location.protocol}//${window.location.host}/api`,
  locale: Locale.esES,
  parentWindowUrl: 'https://mm-es-es--dev.custhelp.com/',
  logging: true,

  geolocationRequestTimeout: 20000,

  surveyType: 'internal',
  botName: BotName.Emmi,
  allOutletsClosed: false,
  showOutletInformation: false,
  showOutletStatus: true,
  salesLineLabel: SalesLineLabel.MediaMarkt,
  covidGeneralInformationUrl:
    'https://www.mediamarkt.es/es/specials/medidas-covid',

  triggerContactCardAfterNegativeFlowFeedback: true,
  showContactCardIndicator: false,
  showContactCardPhoneNumber: false,
  callCenterTimezone: TimeZone.EurMadrid,
  showOpeningTimeExceptions: false,

  enableClickTracking: false,

  productCardStrikePricePrefix: '',
  productCardStrikePriceCrossedOut: true,

  hideOutletPhoneNumber: false,
};

export default environment;
